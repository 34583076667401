<script setup lang="ts">
import { register } from 'swiper/element/bundle';
import type { CmsBlock } from '@shopware-pwa/types';

register();

const props = defineProps<{ content: CmsBlock }>();

// const teasers = props.content.slots; //
const teasers = [
  {
    id: '1',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/Herren-min.png',
    },
    name: 'Herren',
    url: '/Herren/',
  },
  {
    id: '2',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/Damen-min.png',
    },
    name: 'Damen',
    url: '/Damen/',
  },
  {
    id: '3',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/Accessories-min.png',
    },
    name: 'Accessories',
    url: '/Accessoires/',
  },
];
</script>
<template>
  <div class="lg:px-15 hover-shop-section px-5 py-10 lg:py-20">
    <div class="flex flex-col gap-2">
      <div class="mb-10 flex justify-between">
        <h2>{{ props.content.name }}</h2>
      </div>
    </div>
    <div class="block grid-cols-3 overflow-visible lg:grid">
      <div class="image-left relative mx-auto lg:w-[500px]">
        <NuxtImg
          class="aspect-square w-full object-cover lg:w-[500px]"
          :src="teasers[0].media.url"
          :alt="teasers[0].name"
        />
        <RouterLink
          :to="teasers[0].url"
          class="btn btn-secondary btn-solid -lg:translate-x-0 absolute bottom-20 left-1/2 block w-fit -translate-x-1/2 px-20 lg:left-1/2"
        >
          {{ teasers[0].name }}
        </RouterLink>
      </div>

      <div class="image-center relative mx-auto lg:w-[500px]">
        <NuxtImg
          class="z-1 relative aspect-square w-full object-cover lg:w-[500px]"
          :src="teasers[1].media.url"
          :alt="teasers[1].name"
        />
        <RouterLink
          :to="teasers[1].url"
          class="btn btn-secondary btn-solid z-2 absolute bottom-20 left-1/2 block w-fit -translate-x-1/2 px-20"
        >
          {{ teasers[1].name }}
        </RouterLink>
      </div>

      <div class="image-right relative mx-auto lg:w-[500px]">
        <NuxtImg
          class="aspect-square w-full object-cover lg:w-[500px]"
          :src="teasers[2].media.url"
          :alt="teasers[2].name"
        />
        <RouterLink
          :to="teasers[2].url"
          class="btn btn-secondary btn-solid -lg:translate-x-full absolute bottom-20 left-1/2 block w-fit -translate-x-1/2 px-20"
        >
          {{ teasers[2].name }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.hover-shop-section {
  --slide-width: 5rem;

  background: white;
  color: var(--color-dark);
  transition: all 0.5s ease;

  .image-left img,
  .image-right img {
    transition: all 0.5s ease;
  }

  .image-left img {
    transform: translateX(0) rotate(-5deg);
  }

  .image-right img {
    transform: translateX(0) rotate(5deg);
  }

  .image-left img,
  .image-right img {
    transition: all 0.5s ease;
  }

  @media screen and (min-width: 1080px) {
    &:hover {
      background: var(--color-dark);
      color: var(--color-light);

      .image-left img {
        transform: translateX(var(--slide-width)) rotate(-5deg);
      }

      .image-right img {
        transform: translateX(calc(var(--slide-width) * -1)) rotate(5deg);
      }
    }
  }
}
</style>
